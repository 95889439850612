import { EmbeddedLayout, LbLogoLoading, useModules } from "@lb/frontend";
import { drmClientUrls } from "@lb/utils";
import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
const AccessDenied = lazy(() => import("./pages/AccessDenied"));

const EmbeddedDocuments = lazy(() => import("./pages/Documents"));

const EmbeddedDocumentUploadEdit = lazy(() =>
  import("./pages/Documents/UploadEdit")
);

const EmbeddedEditDocument = lazy(() => import("./pages/Documents/Edit"));

const Documents = lazy(() => import("./pages/Documents"));

const DocumentUploadEdit = lazy(() => import("./pages/Documents/UploadEdit"));

const EditDocument = lazy(() => import("./pages/Documents/Edit"));

const Tags = lazy(() => import("./pages/Tags"));

const AddTags = lazy(() => import("./pages/Tags/AddEdit"));

const EditTags = lazy(() => import("./pages/Tags/AddEdit"));

const AppLayout = lazy(() => import("./components/AppLayout"));

const router = (permissions) =>
  createBrowserRouter([
    {
      path: "/embedded",
      element: <EmbeddedLayout />,
      children: [
        {
          path: drmClientUrls.documents.slug,
          element: <EmbeddedDocuments />,
        },
        {
          path:
            drmClientUrls.documents.slug +
            "/" +
            drmClientUrls.documents.upload.slug,
          element: <EmbeddedDocumentUploadEdit />,
        },
        {
          path:
            drmClientUrls.documents.slug +
            "/" +
            drmClientUrls.documents.update.slug,
          element: <EmbeddedEditDocument />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<LbLogoLoading />}>
          <AppLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={drmClientUrls.documents.slug} replace />,
        },
        {
          path: drmClientUrls.documents.slug,
          children: [
            {
              path: "",
              element: <Documents />,
              children: [
                {
                  path: drmClientUrls.documents.upload.slug,
                  element: permissions?.drm?.documents?.create ? (
                    <DocumentUploadEdit />
                  ) : (
                    <AccessDenied />
                  ),
                },
              ],
            },
            {
              path: drmClientUrls.documents.update.slug,
              element: permissions?.drm?.documents?.edit ? (
                <EditDocument />
              ) : (
                <AccessDenied />
              ),
            },
          ],
        },
        {
          path: drmClientUrls.tags.slug,
          element: permissions?.drm?.tags ? <Tags /> : <AccessDenied />,
          children: [
            {
              path: drmClientUrls.tags.create.slug,
              element: permissions?.drm?.tags?.create ? (
                <AddTags />
              ) : (
                <Navigate to={drmClientUrls.base} />
              ),
            },
            {
              path: drmClientUrls.tags.update.slug,
              element: permissions?.drm?.tags?.update ? (
                <EditTags />
              ) : (
                <Navigate to={drmClientUrls.base} />
              ),
            },
          ],
        },
      ],
    },
  ]);

export default router;
